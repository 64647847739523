export default {
  home: '/',
  signin: '/signIn',
  fortgotPass: '/forgotpassword',
  register: '/register',
  signedin: '/signedin',
  preferences: '/preferences',
  upload: '/upload',
  profile: '/profile',
  editinfo: '/editinfo',
  setProfile: '/setProfile',
  viewReviewers: '/viewReviewers',
  skills: '/skills',
  danceChat: '/danceAI',
  choreographyAssistance: '/choreo',
  match: '/match',
  depthSkills: '/depthSkills',
};
