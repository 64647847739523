import Navbar from '../../components/navBar/navbar';

//Specific Physical Technical Skills

const SideNavbar = () => {
  return (
    <div className="left-sidebar">
      <a href="#ballet jumps">Ballet Jumps</a>
      <a href="#ballet leaps">Ballet Leaps</a>
      <a href="#ballet turns">Ballet Turns</a>
      <a href="#ballet leg extensions">Ballet Leg Extensions</a>
      <a href="#ballet upper body extensions">Ballet Upper Body Extensions</a>
      <a href="#ballet basic positions">Ballet Basic Positions</a>
      <a href="#tap steps">Tap Steps</a>
      <a href="#tap turns">Tap Turns</a>
      <a href="#tap combinations">Tap Combinations</a>
      <a href="#tap flaps">Tap Flaps</a>
      <a href="#tap buffalo">Tap Buffalo</a>
      <a href="#tap pushbacks">Tap Pushbacks</a>
      <a href="#tap irish">Tap Irish</a>
      <a href="#tap wings">Tap Wings</a>
      <a href="#jazz jumps">Jazz Jumps</a>
      <a href="#jazz turns">Jazz Turns</a>
      <a href="#jazz combinations">Jazz Combinations</a>
      <a href="#jazz kicks">Jazz Kicks</a>
      <a href="#jazz leaps">Jazz Leaps</a>
      <a href="#jazz floorwork">Jazz Floorwork</a>
      <a href="#jazz footwork">Jazz Footwork</a>
      <a href="#tumbling">Tumbling</a>
      <a href="#contortion">Contortion</a>
      <a href="#modern/contemporary techniques">
        Modern/Contemporary Techniques
      </a>
      <a href="#modern/contemporary floorwork">Modern/Contemporary Floorwork</a>
      <a href="#modern/contemporary movements">Modern/Contemporary Movements</a>
      <a href="#modern/contemporary combinations">
        Modern/Contemporary Combinations
      </a>
      <a href="#modern/contemporary turns">Modern/Contemporary Turns</a>
      <a href="#modern/contemporary footwork">Modern/Contemporary Footwork</a>
      <a href="#modern/contemporary jumps">Modern/Contemporary Jumps</a>
      <a href="#modern/contemporary lifts">Modern/Contemporary Lifts</a>
      <a href="#modern/contemporary partnering">
        Modern/Contemporary Partnering
      </a>
      <a href="#lyrical techniques">Lyrical Techniques</a>
      <a href="#lyrical turns">Lyrical Turns</a>
      <a href="#lyrical jumps">Lyrical Jumps</a>
      <a href="#lyrical footwork">Lyrical Footwork</a>
      <a href="#lyrical floorwork">Lyrical Floorwork</a>
      <a href="#lyrical partnering">Lyrical Partnering</a>
      <a href="#hip-hop styles">Hip-Hop Styles</a>
      <a href="#hip-hop grooves">Hip-Hop Grooves</a>
      <a href="#hip-hop combinations">Hip-Hop Combinations</a>
      <a href="#hip-hop freeze">Hip-Hop Freeze</a>
      <a href="#hip-hop partnering">Hip-Hop Partnering</a>
      <a href="#ballroom tango">Ballroom Tango</a>
      <a href="#ballroom waltz">Ballroom Waltz</a>
      <a href="#ballroom foxtrot">Ballroom Foxtrot</a>
      <a href="#ballroom quickstep">Ballroom QuickStep</a>
      <a href="#ballroom chacha">Ballroom ChaCha</a>
      <a href="#ballroom rumba">Ballroom Rumba</a>
      <a href="#ballroom samba">Ballroom Samba</a>
      <a href="#ballroom chive">Ballroom Chive</a>
      <a href="#modern african">Modern African</a>

      <a href="#bharatynatham adavus">Bharatynatham Adavus (basic steps)</a>
      <a href="#bharatynatham hastas">Bharatynatham Hastas (hand gestures)</a>
      <a href="#barathynatham raagas">Barathynatham Raagas (Musical Modes)</a>
    </div>
  );
};

function DepthSkills() {
  return (
    <div>
      <Navbar />
      <SideNavbar />
      <h1>Ballet:</h1>
      <h2 id="ballet jumps">Jumps:</h2>
      <ul>
        <li>
          <p>
            Jete: A jump in which one *** is brushed forward **** the air *****
            the other leg pushes off the floor, with *** body turning away from
            the lifted leg.
          </p>
        </li>
        <li>
          <p>
            Cabriole: A jump in which the working leg is thrown into the air,
            with the other *** following and beating against it before both ****
            land ** the ground.
          </p>
        </li>
        <li>
          <p>
            Jete battu: A jump similar to a regular jete, but with a beat of the
            legs in the air.
          </p>
        </li>
        <li>
          <p>
            Assemble: A jump in ***** the working leg is brushed into *** air
            and the ***** leg follows, with **** legs coming together in the air
            before landing.
          </p>
        </li>
        <li>
          <p>
            Brise: A jump in which the working leg is brushed into *** air and
            *** other *** beats against it before both legs land on the ground.
          </p>
        </li>
        <li>
          <p>
            Entrechat: A jump in which the **** are crossed and beaten in the
            *** before landing.
          </p>
        </li>
        <li>
          <p>
            Sissone: A **** in which *** working leg is brushed into the air and
            the other leg follows, with both legs landing at *** same time in a
            crossed position.
          </p>
        </li>
        <li>
          <p>
            Saute: A basic jump in which **** feet leave the floor *** land at
            the same time.
          </p>
        </li>
        <li>
          <p>
            Echappe saute: A jump in which the feet start in a closed position
            and then open to a second position in the air before landing.
          </p>
        </li>
        <li>
          <p>
            Changement: A jump in which the feet switch positions in the air
            before landing.
          </p>
        </li>
        <li>
          <p>
            Echappe: A jump in which the feet start in a closed position and
            then open ** a second position before returning to a closed position
            in the air before landing.
          </p>
        </li>
        <li>
          <p>
            Petite Allegro: A term for small, quick jumps performed in a series.
          </p>
        </li>
      </ul>
      <h2 id="ballet leaps">Leaps:</h2>
      <ul>
        <li>
          <p>
            Grand allegro: A term for large, traveling jumps performed with
            elevation.
          </p>
        </li>
        <li>
          <p>
            Saut de chat: A leap in which the working *** is thrown into the air
            and the other leg follows, with the **** turning ******* the lifted
            leg.
          </p>
        </li>
        <li>
          <p>
            Waltz step: A leap in ***** the working leg is lifted into the air
            and the other leg follows, with both legs landing in a lunge
            position.
          </p>
        </li>
        <li>
          <p>
            Pas de chaut: A leap in which the working leg is lifted into the air
            *** the other *** follows, with both legs landing in a crossed
            position.
          </p>
        </li>
        <li>
          <p>
            ***** jete: A leap in which the working leg is brushed into the air
            and the other leg pushes off *** floor, with both legs coming
            together in the air ****** landing.
          </p>
        </li>
        <li>
          <p>
            Pas de Basque: A leap ** which *** working leg is lifted into the
            air and the other leg follows, with both legs landing in a crossed
            position.
          </p>
        </li>
        <li>
          <p>
            Tour jete: A **** for a jump that combines a tour (turn) and a jete.
          </p>
        </li>
      </ul>
      <h2 id="ballet turns">Turns:</h2>
      <ul>
        <li>
          <p>
            Soutenu: A turn in which the feet stay in the same position while
            the body turns.
          </p>
        </li>
        <li>
          <p>
            Chaine combinations: A series ** turns in which the feet stay close
            together *** alternate directions.
          </p>
        </li>
        <li>
          <p>
            Tour combinations: A ****** of turns in which the feet travel in a
            straight line ***** *** body turns.
          </p>
        </li>
        <li>
          <p>
            Pirouette: A turn in which the ******* *** is lifted and the ****
            turns on *** supporting leg.
          </p>
        </li>
        <li>
          <p>
            Renverse: A turn in which the working leg is lifted and the ****
            turns towards the lifted leg.
          </p>
        </li>
        <li>
          <p>
            Pirouette a la seconde: A turn in which the working leg is lifted **
            the **** and the body turns on the supporting leg.
          </p>
        </li>
        <li>
          <p>
            Fouette: A term for a turn that combines a ********* and a whip of
            the working leg.
          </p>
        </li>
        <li>
          <p>
            Promenade Arabesque: A slow *** graceful turn ** which the dancer
            balances on one leg and rotates the other leg while holding it in an
            ********* position.
          </p>
        </li>
      </ul>
      <h2 id="ballet lower body extensions">Lower Body Extensions:</h2>
      <ul>
        <li>
          <p>
            ***** de Jambe: A ******** movement ** the leg that can be performed
            on the ground or ** the air. It can ** done en dehors (outward) or
            en dedans (inward).
          </p>
        </li>
        <li>
          <p>
            Lunge: A **** bend ** the front leg while the back leg is extended
            straight behind. It is often used as a preparation *** jumps or
            turns.
          </p>
        </li>
        <li>
          <p>
            Piqu&eacute;: A step ** which the dancer steps onto a straight leg
            with a pointed foot, usually preceded by a preparation such as a
            pli&eacute; ** a tendu.
          </p>
        </li>
        <li>
          <p>
            D&eacute;velopp&eacute;: A movement in which the working leg is
            lifted to *** front, side, or back and then extended to a fully
            straightened position.
          </p>
        </li>
        <li>
          <p>
            Pench&eacute;: A movement in which the dancer leans forward from the
            waist with one leg extended behind and the other leg supporting the
            body. It requires a ***** deal of balance and strength.
          </p>
        </li>
        <li>
          <p>
            Arabesque: A pose ** which the dancer stands on one leg with the
            other leg extended behind and the torso tilted forward, creating a
            graceful curve.
          </p>
        </li>
        <li>
          <p>
            Attitude: A **** similar to arabesque, *** with the lifted leg bent
            at the knee so that the foot is lifted towards the back of the
            thigh.
          </p>
        </li>
        <li>
          <p>
            Frapp&eacute;: A sharp and quick movement in ***** the **** is
            struck against the floor, either in front or behind the standing
            leg. It can be done **** a ******* or ****** foot.
          </p>
        </li>
        <li>
          <p>
            *** de Pass&eacute;: A movement in which the working foot passes the
            standing leg before extending to the front, side, or back. ** can be
            done as a preparation for a jump or turn.
          </p>
        </li>
        <li>
          <p>
            Fondu: A skill where the ******** leg bends while the working leg
            moves and is extended, typically to the front or back. This skill
            can ** performed in various positions, such as ** first, second, or
            fifth position, *** is ***** used as a preparation for other skills.
          </p>
        </li>
        <li>
          <p>
            Tendu: A skill where *** foot is extended along the floor ***** only
            the toes ****** in ******* **** the ground. This skill can be
            performed in various positions, such as in first, second, or fifth
            position, and is often used ** a preparation *** other skills.
          </p>
        </li>
        <li>
          <p>
            Battement: A skill where the working leg ** lifted and extended in
            the air while the standing leg remains straight. **** skill can be
            performed to the front, side, or back, and can be done with a
            variety ** rhythms and tempos.
          </p>
        </li>
        <li>
          <p>
            Plie: A ***** ***** the knees bend while the body remains upright.
            **** skill can be performed in ******* positions, such as in first,
            second, or fifth position, and is ***** **** as a preparation for
            other skills. Plies can be done in ********* tempos and can help to
            develop strength ** the legs and feet.
          </p>
        </li>
      </ul>
      <h2 id="ballet upper body extensions">Upper Body Extensions:</h2>
      <ul>
        <li>
          <p>
            Port de Bras: This term refers to the ******** or carriage of the
            arms in ballet. ** is a French term that translates to
            &quot;******** of *** arms.&quot; Port de **** involves the
            dancer&apos;s **** ****** in various ********** while maintaining a
            certain posture *** alignment of the torso and head. It is an
            essential ****** of ballet ********* as it allows for ******** and
            fluid movements that are a hallmark of ballet.
          </p>
        </li>
        <li>
          <p>
            Cambre: This term ****** to the bending of the body or ***** in
            ballet. It is a French **** that translates to &quot;arched.&quot;
            Cambre movements involve *** dancer bending backwards ** forwards
            from the waist, ***** maintaining proper alignment of the head,
            neck, and shoulders. Cambre is often used as a transition or
            embellishment in ballet choreography, and it requires a great deal
            of flexibility and control.
          </p>
        </li>
        <li>
          <p>
            Epaulement: **** term refers to the *********** of the shoulders in
            ballet. It ** a French term that translates **
            &quot;shouldering.&quot; Epaulement involves the dancer using their
            shoulders and upper body ** create different angles and ***** in
            ***** movements. It is an important aspect of ****** technique as it
            helps to create a ***** ** depth and dimensionality in a
            dancer&apos;s performance. Epaulement is often used ** conjunction
            with other ballet skills, such as pirouettes and arabesques.
          </p>
        </li>
      </ul>
      <h2 id="ballet basic positions">Basic Positions:</h2>
      <ul>
        <li>
          <p>
            Croise devant: **** is a French term that means &quot;crossed in
            front.&quot; It refers to a position of the legs and feet where one
            foot is placed in front of the other, with the toes of *** back ****
            touching the heel of the front foot. The legs are crossed in a
            diagonal direction.
          </p>
        </li>
        <li>
          <p>
            Quatrieme devant: This is another French **** that *****
            &quot;****** ** front.&quot; ** ****** to a position of the legs and
            feet where one foot is placed in front of the other, **** the heel
            of the front foot touching the ****** of the **** foot. The legs ***
            turned out from the hip and the knees are bent, creating a diamond
            shape between the legs.
          </p>
        </li>
        <li>
          <p>
            ****** devant: This ** a French term that means &quot;open **
            front.&quot; It refers to a position of the legs and feet where ***
            foot is placed in front of *** other, with the toes of the front
            foot pointing *** to *** side and the heel of the back foot touching
            the toes of the front foot. The **** are turned out from the hip and
            the knees are straight.
          </p>
        </li>
        <li>
          <p>
            A la seconde: This is a French term that ***** &quot;to the
            side.&quot; It refers to a position of the legs and feet where the
            legs are turned *** from *** hip and extended ** the side, with the
            feet in a parallel position. The arms are usually extended to the
            sides as well.
          </p>
        </li>
        <li>
          <p>
            ****** derriere: **** is a French term that means &quot;crossed **
            back.&quot; It refers to a position ** the legs and feet ***** one
            foot is placed behind the other, with the toes of the back ****
            touching the heel of *** front foot. The legs *** crossed in a
            diagonal direction.
          </p>
        </li>
        <li>
          <p>
            Ecarte: This ** a French term that means &quot;separated.&quot; It
            refers to a position ** *** legs and feet where one foot is placed
            ** front of the other, with the toes of the front foot pointing
            forward and the heel of the back foot lifted off the ground. The
            legs are turned out from the hip and *** knees are straight.
          </p>
        </li>
        <li>
          <p>
            Epaule: This is a French term **** means &quot;shoulder.&quot; It
            refers to a movement of the shoulders and upper body ** ballet,
            often **** to *** expression and fluidity to dance movements.
          </p>
        </li>
        <li>
          <p>
            Quatrieme derriere: **** is a French term that ***** &quot;****** in
            back.&quot; It refers to a position of the legs and feet where one
            **** is placed behind the other, with the heel of the back foot
            touching the middle of *** ***** foot. The legs are turned *** from
            the hip and the knees are bent, creating a diamond shape between the
            legs.
          </p>
        </li>
        <li>
          <p>
            Efface derriere: This is a French term that means &quot;open in
            back.&quot; It refers ** a position of the legs and feet where one
            foot is placed behind the other, with the toes ** the **** foot
            pointing out ** the **** and the heel of the ***** foot touching the
            **** of the **** foot. The **** are turned out **** *** hip and the
            knees *** straight.
          </p>
        </li>
      </ul>

      <h1 id="tap steps">Tap&nbsp;</h1>

      <h2>Steps:</h2>
      <ul>
        <li>
          <p>
            Shuffle: A quick, traveling step where the ****** alternates tapping
            the ball and heel of their foot on the ground in a side-to-side
            motion.
          </p>
        </li>
        <li>
          <p>
            Flap: A step where the dancer brushes ***** foot forward and then
            quickly drops the heel to create a sound.
          </p>
        </li>
        <li>
          <p>
            Ball change: A two-**** movement where the ****** shifts their
            weight onto one foot, then quickly switches weight ** the other
            foot.
          </p>
        </li>
        <li>
          <p>
            Hop: A small **** where the dancer pushes off from the ground with
            one foot and lands on the same foot.
          </p>
        </li>
        <li>
          <p>
            Leap: A large jump where the dancer pushes off from the ground with
            one foot and lands on the other foot.
          </p>
        </li>
        <li>
          <p>
            Jump: A generic term for any type of movement that involves *******
            off *** ground with both feet and landing ** both feet.
          </p>
        </li>
        <li>
          <p>
            Brush: A movement ***** the dancer quickly ****** their **** forward
            or back, creating a brushing ***** on the floor.
          </p>
        </li>
        <li>
          <p>
            Scuff: A movement where *** dancer ******* drags their foot along
            the floor, creating a scuffing sound.
          </p>
        </li>
        <li>
          <p>
            Stomp: A movement where *** dancer forcefully brings ***** foot down
            ** the ground, creating a loud thumping sound.
          </p>
        </li>
        <li>
          <p>
            Stamp: A movement ***** *** dancer ****** ***** **** down on the
            ground forcefully, but without ******* it off the ground.
          </p>
        </li>
        <li>
          <p>
            Heel drop: A movement where the dancer drops their heel ** the
            ground forcefully.
          </p>
        </li>
        <li>
          <p>
            Toe drop: A movement ***** the dancer ***** the **** of their ****
            to the ****** forcefully.
          </p>
        </li>
        <li>
          <p>
            Dig: A movement where the dancer quickly taps *** ball of their foot
            on the ground, creating a sharp sound.
          </p>
        </li>
        <li>
          <p>
            Cramp roll: A movement ***** the dancer alternates ******* the ball
            and heel of their foot in a ******* motion.
          </p>
        </li>
        <li>
          <p>
            Maxie ford: A step ***** the dancer brushes their foot forward ***
            then quickly steps onto it, ******* **** the ***** foot.
          </p>
        </li>
        <li>
          <p>
            Time step: A step that involves a ****** of ***** movements,
            including steps, brushes, *** stomps, that are ********* done in a
            specific sequence.
          </p>
        </li>
        <li>
          <p>
            Buffalo: A **** where the dancer steps forward, then kicks *****
            foot up and over ***** other foot, **** steps back onto their
            original foot.
          </p>
        </li>
        <li>
          <p>
            Waltz clog: A step where the dancer takes three steps, pivoting on
            the third step, and then hops **** the other foot.
          </p>
        </li>
        <li>
          <p>
            Shuffle ball change: A step that combines a shuffle with a ball
            change.
          </p>
        </li>
        <li>
          <p>
            Irish: A ***** of ***** that originated in Ireland, ************* by
            quick footwork and an upright upper body posture.
          </p>
        </li>
      </ul>
      <h2 id="tap turns">Turns:</h2>
      <ul>
        <li>
          <p>
            Pivot turn: A **** where *** dancer ***** forward, pivots ** one
            foot, and then ***** back onto the other foot.
          </p>
        </li>
        <li>
          <p>
            Paddle turn: A **** ***** *** dancer alternates tapping the ball of
            ***** foot and **** stepping onto it while turning.
          </p>
        </li>
        <li>
          <p>
            Single turn: A turn where *** dancer rotates 360 degrees on one
            foot.
          </p>
        </li>
        <li>
          <p>
            Double turn: A turn where the dancer rotates 720 ******* on one
            foot.
          </p>
        </li>
        <li>
          <p>
            Triple turn: A turn where the dancer rotates 1080 degrees on one
            foot.
          </p>
        </li>
      </ul>
      <h2 id="tap combinations">Combinations:</h2>
      <ul>
        <li>
          <p>
            Shim sham: A popular tap ***** routine that involves a series of
            ***** steps, including shuffles, **** changes, and brushes.
          </p>
        </li>
        <li>
          <p>
            **** step combinations: Combinations of different time steps that
            *** linked together.
          </p>
        </li>
        <li>
          <p>
            Riff walk: A combination of steps that includes a riff and a walk.
          </p>
        </li>
        <li>
          <p>
            ***** roll combinations: Combinations of different cramp rolls that
            *** linked together.
          </p>
        </li>
        <li>
          <p>
            ***** **** combinations: Combinations of different waltz clog steps
            that are linked together.
          </p>
        </li>
      </ul>
      <h2 id="tap flaps">Flaps:</h2>
      <p>
        **** **** change - A *** dance **** **** ******** ******* the ball of
        one foot, then quickly changing ****** and hitting the ball of the other
        foot, while making a small jumping motion.
      </p>
      <p>
        Flap heel - A tap dance step that involves hitting the **** of one foot
        *** then the heel of the same foot, while making a ***** jumping motion.
      </p>
      <p>
        Flap shuffle - A tap dance step that involves hitting the ball of one
        foot, followed by a shuffle **** with the same foot, while making a
        small jumping motion.
      </p>

      <h2 id="tap buffalo">Buffalo:</h2>
      <p>
        Single buffalo - A tap dance step **** ******** ******** one foot
        forward, **** back, then forward again, while hopping ** the other foot.
      </p>
      <p>
        Double buffalo - A tap dance **** that ******** swinging one foot
        forward, **** back, then forward again twice, while hopping on the other
        foot.
      </p>
      <p>
        ********* buffalo - A tap ***** step **** involves ********** a buffalo
        step while ****** forward or backward.
      </p>

      <h2 id="tap pullbacks">Pullbacks:</h2>
      <p>
        Single pullback - A tap dance step that involves dragging one foot ****
        quickly, then ******* it and tapping it in front ** the other foot.
      </p>
      <p>
        Double pullback - A tap dance step that ******** dragging one foot back
        ******* twice, then ******* it and tapping it ** ***** of the other
        foot.
      </p>
      <p>
        Traveling pullbacks - A *** dance step **** ******** performing a
        pullback step while moving forward ** backward.
      </p>

      <h2 id="tap irish">Irish:</h2>

      <p>
        Traditional Irish steps - A type of step dancing that originated in
        ******* and is ************* ** intricate footwork *** a rigid upper
        body.
      </p>
      <p>
        Irish fling - A lively dance in the traditional Irish style, typically
        performed by a solo dancer.
      </p>

      <h2 id="tap wings">Wings:</h2>

      <p>
        Shuffle ***** - A *** dance step that involves performing a *******
        step, followed by a wing step, while moving forward or backward.
      </p>
      <p>
        Double wings - A *** dance step that involves performing two wing steps
        in succession.
      </p>
      <p>
        Triple wings - A tap dance step that involves performing three wing
        steps in succession.
      </p>

      <h1>Jazz:</h1>

      <h2 id="jazz jumps"> Jumps:</h2>
      <ul>
        <li>
          <p>
            Jet&eacute;: a jump in which the ****** ***** *** from *** foot and
            lands on the other foot while ******** the first *** out in front **
            behind the body.
          </p>
        </li>
        <li>
          <p>
            Leap: a long jump from one foot to the other, where the dancer is
            airborne for a moment.
          </p>
        </li>
        <li>
          <p>
            Stag jump: a jump where the dancer bends *** leg and brings the foot
            up to *** opposite knee while jumping.
          </p>
        </li>
        <li>
          <p>
            Toe touch: a **** where *** dancer ***** in the air and touches
            their toes with both hands.
          </p>
        </li>
        <li>
          <p>
            Calypso: a **** where the dancer jumps, turns in *** air, and *****
            with the opposite foot in front.
          </p>
        </li>
        <li>
          <p>
            Split leap: a **** where the dancer jumps in the air and splits
            their legs apart, one in front *** one in back.
          </p>
        </li>
        <li>
          <p>
            Turning jumps: a ******* of jumps that ******* turning in the air,
            such ** a fouett&eacute; or a tour jet&eacute;.
          </p>
        </li>
        <li>
          <p>
            Jet&eacute; entrelac&eacute;: a jump where the dancer ***** in the
            air and performs a turn ***** ********* one leg out to the side.
          </p>
        </li>
      </ul>
      <h2 id="jazz turns">Turns:</h2>
      <ul>
        <li>
          <p>
            Pirouette: a turn on one foot, ******* performed with *** other leg
            held in pass&eacute; position.
          </p>
        </li>
        <li>
          <p>
            Pique turn: a **** where the dancer ***** onto *** ball of one foot
            and ***** on **** foot.
          </p>
        </li>
        <li>
          <p>
            Chaines: a ****** of rapid, half-turns performed in a straight ****
            or a circle.
          </p>
        </li>
        <li>
          <p>
            Fouette turns: a turn where the dancer kicks one leg *** *** uses
            the momentum to turn on the other foot.
          </p>
        </li>
        <li>
          <p>
            Barrel turn: a turn where *** dancer ***** ** a barrel-like motion,
            with the arms and legs extending out.
          </p>
        </li>
        <li>
          <p>
            Jazz turn: a turn where the dancer turns on *** foot and kicks the
            other *** *** ** *** side.
          </p>
        </li>
      </ul>
      <h2 id="jazz combinations">Combinations:</h2>
      <ul>
        <li>
          <p>
            **** squares: a four-step combination where the dancer steps
            forward, side, back, and side, creating a square shape **** *****
            feet.
          </p>
        </li>
        <li>
          <p>
            **** ****** hop: a three-step combination where *** dancer performs
            a ball change (a quick ****** ******** ******* feet) and then hops
            on one foot.
          </p>
        </li>
        <li>
          <p>
            Kick **** change: a three-step combination where *** dancer kicks
            one *** out, performs a ball change, and then steps forward or back.
          </p>
        </li>
        <li>
          <p>
            Pivot step: a step where the dancer steps forward on one foot and
            ****** on that **** ** **** around.
          </p>
        </li>
        <li>
          <p>
            Chaine *** pique turns: a combination ** chaine turns and pique
            turns.
          </p>
        </li>
        <li>
          <p>
            Step ball change: a three-step combination where the dancer steps
            forward, performs a ball change, *** steps back.
          </p>
        </li>
        <li>
          <p>
            Flick ball change: a three-step combination where the dancer ******
            *** foot out and then performs a ball change.
          </p>
        </li>
      </ul>
      <h2 id="jazz kicks">Kicks:</h2>
      <ul>
        <li>
          <p>
            Fan kicks: a kick where the dancer kicks one leg out to the side and
            then brings it back in, ******** a fan shape with ***** leg.
          </p>
        </li>
        <li>
          <p>
            Toe touch: a jump ***** the dancer ***** in *** air and touches
            their toes with both hands.
          </p>
        </li>
        <li>
          <p>
            Scissor kicks: a **** where the dancer jumps and ******* one leg in
            front of *** other, alternating legs with **** jump.
          </p>
        </li>
        <li>
          <p>
            **** kicks: a kick where the dancer ***** one leg and brings the
            **** up to the opposite knee while kicking the other leg out.
          </p>
        </li>
        <li>
          <p>
            Side kicks: a kick where the dancer kicks one leg out to the side.
          </p>
        </li>
        <li>
          <p>
            Attitude kicks: a kick where the dancer lifts one leg behind the
            body *** bends the knee, creating an attitude position with *****
            leg.
          </p>
        </li>
      </ul>
      <h2 id="jazz leaps">Leaps:</h2>
      <ul>
        <li>
          <p>
            Chass&eacute; leap: a leap where the dancer ******** a chass&eacute;
            step and **** jumps, landing on the opposite foot.
          </p>
        </li>
        <li>
          <p>
            Switch leap: a leap where *** dancer jumps in *** air and switches
            the position of ***** legs mid-air.
          </p>
        </li>
        <li>
          <p>
            Pas de chat: A *** de chat is a leap that involves the dancer
            jumping and landing on one foot while *** other foot is brought up
            to meet it in the air. ** is ********* performed with a small jump
            *** *** be executed to the front, side, ** back.
          </p>
        </li>
        <li>
          <p>
            Grande jete: A Grande jete is a large, ******** leap that involves a
            running start and a jump from *** foot to the other, with the back
            leg extending fully behind the dancer. It is often **** in ******
            *** jazz dance, and requires a lot ** strength and control.
          </p>
        </li>
        <li>
          <p>
            Leap in second: A **** ** second is a leap **** involves a jump from
            *** foot to the other, with both **** extended to the side of the
            body. It requires a lot of strength and control, and is often seen
            in jazz *** contemporary dance.
          </p>
        </li>
        <li>
          <p>
            Attitude leap: An Attitude leap is a leap that involves the dancer
            ******* and extending one *** to the back of the body while keeping
            the other leg in a bent position. It requires a lot of balance and
            control, and is often seen in ballet and contemporary dance.
          </p>
        </li>
        <li>
          <p>
            Firebird Leap: a dynamic and *********** jazz dance **** ****
            ******** a *** of ***** *** control. It involves a grand jet&eacute;
            (a big leap) with a full turn ** the *** and a second jump in the
            opposite direction, landing ** the same foot. ** the dancer jumps,
            **** ****** *** leg forward and the other leg back, with the arms
            outstretched in opposition. The leap is typically ********* with a
            high jump, a quick turn, and a powerful landing. It requires both
            technical precision and artistic expression, making ** a popular ***
            impressive move in **** dance performances.
          </p>
        </li>
      </ul>
      <h2 id="jazz footwork">Footwork:</h2>
      <ul>
        <li>
          <p>
            Shuffles: A quick back-and-***** movement of the feet, often
            ********* in a syncopated rhythm.
          </p>
        </li>
        <li>
          <p>
            Ball changes: A quick transfer of weight from one **** to the other,
            often *********** by a change of direction.
          </p>
        </li>
        <li>
          <p>
            Stomps: A ***** and forceful movement of the **** into the floor,
            usually performed with a bent knee and a strong contraction ** the
            *** muscles.
          </p>
        </li>
        <li>
          <p>
            Step ball change: A three-step movement that involves stepping ****
            one foot, shifting the weight onto the ***** foot, and then shifting
            the weight back ** the original foot.
          </p>
        </li>
        <li>
          <p>
            Pas ** bourr&eacute;e: A quick and light-footed ******** that
            involves a series of small ***** performed in a specific pattern.
          </p>
        </li>
        <li>
          <p>
            Chasses: A series of quick, gliding steps in which one foot chases
            the other.
          </p>
        </li>
        <li>
          <p>
            Grapevine: A lateral movement that ******** crossing one foot behind
            or in front ** the other and then stepping to *** side with the
            uncrossed foot.
          </p>
        </li>
        <li>
          <p>
            Saut de chat: A leap that involves jumping off one **** and bringing
            the other foot up to meet it in the air ****** landing on *** ******
            foot.
          </p>
        </li>
      </ul>
      <h2 id="jazz floorwork">Floorwork:</h2>
      <ul>
        <li>
          <p>
            Leg swings: A movement in which the legs swing back *** forth in a
            fluid motion, often performed while lying on the floor.
          </p>
        </li>
        <li>
          <p>
            Attitude turns: A turn that involves balancing on one leg while
            holding the other leg in a bent position ** *** knee and turned out
            to *** side.
          </p>
        </li>
        <li>
          <p>
            Leg circles: A movement in which one *** moves in a circular motion
            around the other leg, often performed ***** lying on the floor.
          </p>
        </li>
        <li>
          <p>
            Pirouette floorwork: A technique that involves executing a pirouette
            while lying on the floor, using the arms and legs to generate
            momentum.
          </p>
        </li>
        <li>
          <p>
            Rolls: A movement in which the body rolls ******* or backward along
            the ground, often performed with *** or **** legs lifted off the
            ground.
          </p>
        </li>
        <li>
          <p>
            Knee slides: A movement in which the dancer slides on *** or both
            knees along the floor, often *********** ** *** movements.
          </p>
        </li>
        <li>
          <p>
            Floor turns: A turn **** is performed ***** lying ** the floor,
            often using the arms and legs to generate momentum.
          </p>
        </li>
      </ul>

      <h1>Modern/Contemporary</h1>

      <h2 id="modern/contemporary techniques">Techniques</h2>

      <ul>
        <li>
          <p>
            Release technique: A modern ***** technique that ********** the
            ******* of ******* in the body to create **** fluid and organic
            movements. This technique involves using breath, weight, and
            momentum to create movement and emphasizes the use of the spine and
            joints.
          </p>
        </li>
        <li>
          <p>
            Limon technique: A modern dance technique created by Jose Limon that
            emphasizes the use of breath, weight, and momentum to create
            movement. This technique ********** *** use of fall and recovery ***
            ******** ********* to increase the range of motion *** flexibility
            in the body.
          </p>
        </li>
        <li>
          <p>
            Graham technique: A modern dance technique created by Martha Graham
            that emphasizes the use of *********** *** release to create tension
            and dynamic movement. **** technique includes exercises to increase
            ******** and flexibility ** *** core muscles *** the use ** dramatic
            gestures and movements.
          </p>
        </li>
        <li>
          <p>
            Horton technique: A ****** dance technique ******* ** ****** Horton
            that ********** the use ** flat backs, ******* T&apos;s, and lunges
            to create strength and flexibility ** the body. This technique
            ******** ********* to increase control and coordination of the body.
          </p>
        </li>
        <li>
          <p>
            Cunningham technique: A modern dance technique created by Merce
            Cunningham that emphasizes the use ** chance and ********** to
            create movement. This technique includes exercises to ******** the
            range ** ****** and flexibility in the body and ************
            movements **** different styles and techniques.
          </p>
        </li>
        <li>
          <p>
            ******* improvisation: A dance form that involves two or more
            dancers exploring movement and physical touch through improvisation.
            This ********* involves using weight, momentum, *** touch to create
            movement and relies ******* on the communication and connection
            between the dancers.
          </p>
        </li>
      </ul>

      <h2 id="modern/contemporary floorwork">Floorwork</h2>

      <ul>
        <li>
          <p>
            Roll: A floorwork movement that involves rolling the **** over the
            floor.
          </p>
        </li>
        <li>
          <p>
            Spiral: A floorwork movement that involves spiraling the body around
            *** axis, often using the **** and **** to create movement.
          </p>
        </li>
        <li>
          <p>
            **** slide: A ********* movement that involves sliding *** knee
            along the floor ***** maintaining balance.
          </p>
        </li>
        <li>
          <p>
            C-curve: A movement that involves creating a curve in the ***** **
            the shape of the letter &quot;C.&quot;
          </p>
        </li>
        <li>
          <p>
            Leg swings: A ******** that involves swinging the leg back and
            forth, often used in warm-up exercises.
          </p>
        </li>
        <li>
          <p>
            Undulation: A fluid, wave-like ******** of the body, often used in
            modern *** ************ dance.
          </p>
        </li>
      </ul>

      <h2 id="modern/contemporary movements">Movements</h2>
      <ul>
        <li>
          <p>
            Isolation: A ******** that involves isolating one part of the body
            while keeping the rest of the body still.
          </p>
        </li>
        <li>
          <p>
            Pendulum swing: A movement that involves swinging the body **** a
            pendulum from side to side.
          </p>
        </li>
        <li>
          <p>
            Contraction: A movement that involves pulling the torso ****** to
            ****** tension and compression ** the body.
          </p>
        </li>
        <li>
          <p>
            Release: A ******** that involves ********* ******* in the body to
            create a more fluid and ******* movement.
          </p>
        </li>
        <li>
          <p>
            Fall and recovery: A movement principle that involves falling off
            balance and then recovering to create a dynamic and fluid movement.
          </p>
        </li>
        <li>
          <p>
            Suspension: A movement principle that involves holding a position or
            movement in mid-air.
          </p>
        </li>
        <li>
          <p>
            Sustained movement: A ******** principle that involves maintaining a
            movement or position for an extended period ** time.
          </p>
        </li>
        <li>
          <p>
            Lyrical Skills: Lyrical dance ** a fusion of ballet and **** dance
            styles, with a focus on the expressive interpretation ** music and
            lyrics.
          </p>
        </li>
        <li>
          <p>
            Ballet technique: Ballet is a classical dance form **** ******* on
            technique, including precise movements and positions of the feet,
            arms, and body. It emphasizes graceful *** ***** movements.
          </p>
        </li>
        <li>
          <p>
            Jazz technique: Jazz dance is a ***** that emerged from African
            American ********** ***** in the early 20th century. It focuses on
            ********** rhythms, isolations, and strong, stylized movements.
          </p>
        </li>
        <li>
          <p>
            Contemporary technique: Contemporary dance is a style that combines
            elements ** ballet and modern dance. It emphasizes fluid, expressive
            movements and often incorporates improvisation.
          </p>
        </li>
        <li>
          <p>
            Modern technique: Modern dance is a ***** that emerged in the early
            20th ******* ** a reaction to the rigid rules of classical ballet.
            It emphasizes freedom ** movement and self-expression.
          </p>
        </li>
        <li>
          <p>
            Expressive movements: ***** are movements that convey a ***** of
            emotions and feelings through the dancer&apos;s body language and
            facial expressions.
          </p>
        </li>
        <li>
          <p>
            Connection with music: This involves interpreting and expressing ***
            emotions of the music through dance.
          </p>
        </li>
        <li>
          <p>
            Conveying emotions: The dancer uses **** language, facial
            expressions, and movements to express different emotions.
          </p>
        </li>
        <li>
          <p>
            Telling a story ******* dance: **** involves ***** movement to
            convey a narrative or story.
          </p>
        </li>
        <li>
          <p>
            ******* movements: These are movements that are smooth and fluid,
            with an emphasis on continuity and gracefulness.
          </p>
        </li>
        <li>
          <p>
            Fluid transitions: This involves smoothly transitioning from one
            movement or ******** to another.
          </p>
        </li>
        <li>
          <p>
            Complex turns: These are turns **** ******* a high level of skill
            and technique, often involving multiple rotations or difficult
            footwork.
          </p>
        </li>
        <li>
          <p>
            Emotional variations: This involves expressing a range of emotions
            ******* dance, such as joy, sadness, or anger.
          </p>
        </li>
        <li>
          <p>
            Slow and fast pacing: This involves varying *** speed and intensity
            of ********* ********** a dance.
          </p>
        </li>
        <li>
          <p>Pirouette: A turn on one foot, typically performed in ballet.</p>
        </li>
        <li>
          <p>
            Pique turn: A **** ** the ball of the foot, often ********* in jazz
            or contemporary dance.
          </p>
        </li>
        <li>
          <p>
            Chaines: A series of small, **** ***** that ****** in a straight
            line.
          </p>
        </li>
        <li>
          <p>
            Fouett&eacute; turns: A turn ** ***** the dancer spins on *** ****
            ***** the other leg extends and whips around.
          </p>
        </li>
        <li>
          <p>
            Attitude turn: A turn in which the ****** lifts *** leg behind the
            body and ***** ** the supporting leg.
          </p>
        </li>
        <li>
          <p>**** turns: Various turns that are commonly used in jazz dance.</p>
        </li>
        <li>
          <p>
            Leaps: A jump in which the dancer extends *** leg forward or
            backward while ** the air.
          </p>
        </li>
        <li>
          <p>
            Jet&eacute;: A jump in which the ****** extends one leg forward
            while jumping off the other.
          </p>
        </li>
        <li>
          <p>
            Stag jump: A jump in ***** the dancer extends one leg to the front
            and the other leg to the back while in *** air.
          </p>
        </li>
        <li>
          <p>
            ******* jumps: Jumps that incorporate turns, such ** a ********* or
            fouett&eacute;.
          </p>
        </li>
        <li>
          <p>
            Calypso: A jump in which the dancer kicks one leg forward *****
            simultaneously turning and landing ** the other leg.
          </p>
        </li>
        <li>
          <p>
            Split leap: A leap in which *** ****** extends **** legs ** the
            sides while in *** air.
          </p>
        </li>
        <li>
          <p>
            Shuffles: A step ** which the dancer rapidly moves *** foot back and
            forth ** the floor.
          </p>
        </li>
        <li>
          <p>
            Ball changes: A quick transfer of weight **** one foot to the other.
          </p>
        </li>
        <li>
          <p>
            Stomps: A step ** which the ****** stamps their foot loudly on the
            floor.
          </p>
        </li>
        <li>
          <p>
            Pas de bourr&eacute;e: A step ** ***** the dancer ***** from one
            foot ** *** other in a quick, gliding motion.
          </p>
        </li>
        <li>
          <p>
            Chasses: A chasse is a quick, gliding step ** which one foot
            &quot;chases&quot; the other. It is commonly used in many ****** of
            dance, including ballet, jazz, and contemporary.
          </p>
        </li>
        <li>
          <p>
            Grapevine: A grapevine is a sidestepping movement that involves
            crossing *** foot behind or in front of the other, then stepping to
            the side with the other foot, and repeating the process. It is
            commonly **** in **** dancing and ***** social dances.
          </p>
        </li>
        <li>
          <p>
            Saut de chat: Saut de chat ** a ****** term that ********** to
            &quot;cat jump.&quot; It is a jump that involves a leap from one
            foot to *** other, **** the **** leg lifted high in the air and the
            ***** leg bent at the knee. It is commonly used ** ballet ***
            contemporary dance.
          </p>
        </li>
        <li>
          <p>
            Partnering: Partnering refers to dance movements performed by two
            dancers working together as a team. It requires a strong sense of
            trust and communication ******* the dancers, as **** as a good
            understanding of technique and timing.
          </p>
        </li>
        <li>
          <p>
            Lifts: Lifts are movements in which *** dancer lifts another off ***
            ground. They can be ********* in many different ********* ***
            require a high degree of strength and coordination.
          </p>
        </li>
        <li>
          <p>
            Dips: A dip is a movement in which one dancer dips or ***** backward
            while the other dancer supports them. It is commonly used in partner
            ****** such as salsa and swing.
          </p>
        </li>
        <li>
          <p>
            Illusion turns: An illusion turn is a **** ** turn that creates the
            illusion of *** dancer spinning much faster than they actually are.
            It involves lifting the front leg and quickly turning the body *****
            keeping the lifted leg in *** same position. It is commonly used in
            jazz and contemporary dance.
          </p>
        </li>
      </ul>

      <h2 id="modern/contemporary combinations">Combinations</h2>
      <ul>
        <li>
          <p>
            Lateral T: A combination **** involves stepping to the side with one
            foot and then bringing the other foot to meet it, creating a T shape
            with the body.
          </p>
        </li>
        <li>
          <p>
            Spiral turn: A turn that involves spiraling the **** ****** its axis
            while turning.
          </p>
        </li>
        <li>
          <p>
            Weighted turn: A turn that emphasizes the use of weight and momentum
            to create a fluid and ******* movement.
          </p>
        </li>
        <li>
          <p>
            Floor patterns: Choreographed patterns or pathways on *** floor that
            dancers move through.
          </p>
        </li>
        <li>
          <p>
            Movement phrases: A series of choreographed movements that ****** a
            complete phrase or section of a dance.
          </p>
        </li>
        <li>
          <p>
            Complex turns: Turns that ******* multiple rotations or intricate
            footwork.
          </p>
        </li>
      </ul>

      <h2 id="modern/contemporary jumps">Jumps</h2>
      <ul>
        <li>
          <p>
            **** de chat: A jump **** involves jumping off one leg and bringing
            the other leg up to meet it in the air.
          </p>
        </li>
        <li>
          <p>
            Assembl&eacute;: A jump that involves bringing the feet together in
            *** air before landing.
          </p>
        </li>
        <li>
          <p>
            Axel Turn : ** axel turn in jazz is a turning jump that involves
            ****** off from one foot, performing a half turn in the air, and
            landing on the opposite foot.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Switch leap: A switch leap is a type of leap where the dancer jumps
            off one foot, switches the position ** ***** legs in the air, and
            lands on *** opposite foot. The switch is typically performed in a
            split position, with one leg extended ******* and the other leg
            extended back.
          </p>
        </li>
        <li>
          <p>
            Chass&eacute; jump: A chass&eacute; jump is a type ** jump that
            involves a step, slide, *** leap combination. The ****** ****** **
            ******** onto one foot, sliding the other foot to meet it, and then
            jumping off both feet into the air. The jump can be ********* in
            various directions and with different leg positions.
          </p>
        </li>
        <li>
          <p>
            Grande jete: A grande jete is a **** of leap that involves a large
            split in the air. The ****** typically begins by ******** onto one
            **** and **** ***** into the air ***** extending the other ***
            forward *** *** other leg back. The split position is held in ***
            air before the dancer lands on the opposite foot.
          </p>
        </li>
        <li>
          <p>
            Tilt Jump: A tilt jump in jazz is a type of leap that involves a
            high extension of one leg while the torso is tilted to the opposite
            side. The dancer ****** with one leg extended to the side ***** the
            other *** is bent and lifted to the back. *** torso is then tilted
            towards the lifted leg, while the extended *** ** lifted even
            higher.&nbsp;
          </p>
        </li>
      </ul>

      <h2 id="modern/contemporary turns">Turns:</h2>
      <ul>
        <li>
          <p>
            Pirouette: a turn executed on one leg with the other leg in a
            position such as pass&eacute; or attitude, **** the body turned out.
          </p>
        </li>
        <li>
          <p>
            Chaines: a series of turns ******** on both feet, with the feet
            ******* alternating positions *** *** ***** body remaining facing in
            the same direction.
          </p>
        </li>
        <li>
          <p>
            Pique turn: a turn executed ** stepping onto the ball of one ****
            and turning on that foot, with the other foot in a position such as
            pass&eacute; or attitude.
          </p>
        </li>
        <li>
          <p>
            Fouett&eacute; turns: a series of turns executed on one leg with the
            other leg in a position such as attitude, while the working leg is
            whipped around to gain momentum *** **** turn.
          </p>
        </li>
        <li>
          <p>
            Attitude turn: a turn executed ** one leg **** the other leg in
            attitude position, with the body ****** out
          </p>
        </li>
      </ul>
      <h2 id="modern/contemporary lifts">Lifts:</h2>
      <ul>
        <li>
          <p>
            Cradle lift: a lift where one ****** ******** the other&apos;s back
            and neck while lifting them off the ground and cradling them in
            ***** arms.
          </p>
        </li>
        <li>
          <p>
            **** lift: a lift where the lifted dancer is held in a **********
            position with *** supporting dancer holding their legs while they
            extend their upper body backwards.
          </p>
        </li>
        <li>
          <p>
            Fireman&rsquo;s carry: a lift ***** *** dancer carries the other
            over their shoulder, like a fireman carrying a victim.
          </p>
        </li>
        <li>
          <p>
            ******** sit: a lift where one dancer **** on the other&apos;s
            shoulder with their legs extended in ***** of them.
          </p>
        </li>
        <li>
          <p>
            ******** stag: a lift ***** *** ****** dancer jumps into the *** and
            is ****** and lifted by the other dancer into a stag position.
          </p>
        </li>
      </ul>
      <h2 id="modern/contemporary partnering">Partnering:</h2>
      <ul>
        <li>
          <p>
            Counterbalance: a partnering technique where dancers *** each
            other&apos;s weight to create balance and support each other&apos;s
            movements.
          </p>
        </li>
        <li>
          <p>
            Weight sharing: a partnering technique where dancers share ****
            other&apos;s weight and support each other&apos;s movements through
            physical connection.
          </p>
        </li>
        <li>
          <p>
            Contact improvisation: a form of dance improvisation that emphasizes
            ******** contact between dancers, often involving lifts, rolls, and
            other movements **** require trust and connection ******* partners.
          </p>
        </li>
      </ul>

      <h1>Hip-Hop</h1>

      <h2 id="hip-hop styles">Styles:</h2>

      <ul>
        <li>
          <p>
            Breaking: Breaking, **** known as breakdancing, ** a style of
            Hip-Hop dance that incorporates acrobatic and athletic *********
            such as spins, flips, and freezes.
          </p>
        </li>
        <li>
          <p>
            Locking: Locking is a style of Hip-Hop ***** that involves quick,
            sharp movements and poses, often with exaggerated arm and hand
            gestures.
          </p>
        </li>
        <li>
          <p>
            Popping: Popping is a style of Hip-Hop dance that ******** ******
            tensing and ********* ** muscles to create sharp, staccato
            movements.
          </p>
        </li>
        <li>
          <p>
            Krumping: Krumping is a style of Hip-*** dance that ********** **
            Los Angeles and involves energetic, ********** movements, often ****
            exaggerated facial expressions.
          </p>
        </li>
        <li>
          <p>
            Waacking: Waacking is a ***** of Hip-Hop dance that originated in
            the 1970s and involves ***** *** ********* *** poses.
          </p>
        </li>
        <li>
          <p>
            Vogueing: ******** is a ***** of Hip-Hop dance that originated **
            the LGBTQ+ ballroom scene *** involves sharp, angular movements and
            poses.
          </p>
        </li>
        <li>
          <p>
            House: House is a style of Hip-Hop dance that originated in the
            1980s and ************ ******** of disco and African dance.
          </p>
        </li>
      </ul>

      <h2 id="hip-hop grooves">Grooves:</h2>

      <ul>
        <li>
          <p>
            Footwork: Footwork refers to the movements of the feet ** Hip-Hop
            dance, including steps, turns, and jumps.
          </p>
        </li>
        <li>
          <p>
            Arm movements: Arm movements refer to the movements of the arms in
            Hip-Hop dance, including punches, waves, and poses.
          </p>
        </li>
        <li>
          <p>
            Body rolls: **** rolls are movements in which the dancer smoothly
            rolls their body, often from the head **** ** *** hips.
          </p>
        </li>
        <li>
          <p>
            Shoulder shrugs: Shoulder shrugs are movements in which the dancer
            quickly lifts and drops ***** shoulders.
          </p>
        </li>
        <li>
          <p>
            *** and chest isolations: Hip and chest ********** *** movements in
            which the dancer isolates *** moves only their hips or chest while
            keeping the **** of their body still.
          </p>
        </li>
        <li>
          <p>
            Bounces: Bounces are quick, rhythmic movements in which the dancer
            ******* up and down.
          </p>
        </li>
        <li>
          <p>
            Walks: Walks are basic Hip-Hop dance movements in which the dancer
            ***** in a ******** manner.
          </p>
        </li>
      </ul>

      <h2 id="hip-hop tricks">Tricks:</h2>

      <ul>
        <li>
          <p>
            Windmills: Windmills are a type ** breaking move in which *** dancer
            spins on their back, ********* by their arms and legs.
          </p>
        </li>
        <li>
          <p>
            Headspins: Headspins are a type of breaking move in which the dancer
            ***** on their head.
          </p>
        </li>
        <li>
          <p>
            Flares: Flares are a type of breaking **** in which the dancer spins
            their legs in a circular motion while supporting themselves with
            their arms.
          </p>
        </li>
        <li>
          <p>
            Airtracks: Airtracks are a type of breaking move in which the ******
            jumps and twists ** the air, often using their **** and **** to
            create momentum.
          </p>
        </li>
        <li>
          <p>
            Suicide dives: Suicide dives are a type of breaking move in which
            the dancer jumps *** lands on their chest, then quickly springs back
            up.
          </p>
        </li>
        <li>
          <p>
            Knee spins: Knee spins are a type of breaking move in which the
            dancer spins on one knee while supporting themselves **** their
            hands.
          </p>
        </li>
      </ul>

      <h2 id="hip-hop combinations">Combinations:</h2>

      <ul>
        <li>
          <p>
            Up-rocking: Up-rocking is a style of breaking that involves ********
            footwork *** poses.
          </p>
        </li>
        <li>
          <p>
            Top-rocking: Top-rocking is a style of ******** that involves
            footwork and ***** ********* while standing.
          </p>
        </li>
        <li>
          <p>
            B-boy/B-girl moves: B-boy/B-girl moves refer to the foundational
            breaking moves, such as the six-step and the swipe.
          </p>
        </li>
        <li>
          <p>
            Floorwork combinations: Floorwork combinations are sequences of
            movements performed while on the ground, often in breaking.
          </p>
        </li>
        <li>
          <p>
            Groove combinations: Groove combinations are sequences of movements
            that focus on rhythm and musicality.
          </p>
        </li>
        <li>
          <p>
            Musicality-focused combinations: Musicality-******* combinations ***
            sequences of movements **** are designed to match the music, often
            incorporating pauses *** syncopation.
          </p>
        </li>
      </ul>
      <h1 id="modern african">Modern African</h1>

      <ul>
        <li>
          <p>
            Alkayida - a dance originating from West Africa that involves a lot
            of fast and energetic movements, ********* shaking and twisting of
            the **** and waist.
          </p>
        </li>
        <li>
          <p>
            Akwaba - a traditional dance from West Africa that ******** a *** of
            jumping, turning, and clapping. It is ***** performed at cultural
            events *** festivals.
          </p>
        </li>
        <li>
          <p>
            Azonto - a dance style **** originated in Ghana and involves a lot
            ** complex footwork, as well as arm and body movements. It is often
            ********* to Afrobeat music.
          </p>
        </li>
        <li>
          <p>
            Bella - a traditional dance from Central ****** that involves a ***
            of ******** movements, including jumping and foot stomping. It is
            often performed at weddings and other celebrations.
          </p>
        </li>
        <li>
          <p>
            Chocobodi - a dance style from West ****** that ******** a lot of
            hip and waist movements, as well as arm and *** movements.
          </p>
        </li>
        <li>
          <p>
            Etigi - a ***** style **** West ****** that involves a *** **
            twisting and turning movements, as well as arm *** leg movements.
          </p>
        </li>
        <li>
          <p>
            Gweta - a dance style from West Africa that involves a lot of leg
            movements, as well as *** and hip movements. It is often performed
            to Afrobeat music.
          </p>
        </li>
        <li>
          <p>
            Gwara Gwara - a ***** African dance style **** involves a *** of
            knee and hip movements, as well as *** movements. It became popular
            worldwide ***** ***** featured in a popular music video.
          </p>
        </li>
        <li>
          <p>
            ********** - a South African dance style **** has gained worldwide
            ********** in ****** years, thanks to the viral song
            &quot;Jerusalema&quot; by Master KG. It ******** a lot of footwork
            and arm movements, and is ***** performed in a group.
          </p>
        </li>
        <li>
          <p>
            Jazze - a *********** dance from Central ****** that involves a lot
            of hip and waist movements, as well as arm and leg movements.
          </p>
        </li>
        <li>
          <p>
            Kupe - a dance style from **** ****** that involves a lot of hip and
            waist movements, as well as *** and leg movements. It ** often
            performed to Afrobeat music.
          </p>
        </li>
        <li>
          <p>
            Kpakujemu - a dance ***** from West Africa that involves a lot of
            fast and energetic movements, including footwork and arm movements.
          </p>
        </li>
        <li>
          <p>
            LegWork - a West ******* dance style that involves a lot of ***
            movements, ** well ** arm and *** movements. It is often performed
            to Afrobeat music.
          </p>
        </li>
        <li>
          <p>
            Maplorly - a West African dance ***** that involves a lot **
            energetic movements, including ******* and shaking.
          </p>
        </li>
        <li>
          <p>
            ******* - a West African dance style that involves a lot of **** and
            ******* footwork, as well as arm movements. ** is often ********* to
            Afrobeat music.
          </p>
        </li>
        <li>
          <p>
            *** - an East African dance style **** involves a lot of hip and
            waist movements, as well as arm and leg movements.
          </p>
        </li>
        <li>
          <p>
            Pilolo - a West ******* ***** style that involves a *** of fast and
            energetic movements, including footwork and arm movements.
          </p>
        </li>
        <li>
          <p>
            Shaku shaku - a West African ***** style that involves a lot of ****
            and rhythmic movements, including footwork and arm movements. It
            became popular worldwide after being featured in a popular music
            video.
          </p>
        </li>
        <li>
          <p>
            Sekem - a **** African ***** style that involves a *** of hip ***
            waist movements, as well ** arm *** leg movements. It is often
            performed to Afrobeat music.
          </p>
        </li>
        <li>
          <p>
            Skelewu - a West African dance style that involves a lot of fast and
            energetic movements, including footwork and arm movements. It became
            popular worldwide after being featured in a popular music video.
          </p>
        </li>
        <li>
          <p>
            Shoki - a **** African ***** style that involves a lot of fast and
            rhythmic movements, including footwork *** arm movements. It became
            ******* worldwide after being featured in a ******* music video.
          </p>
        </li>
      </ul>

      <h1>Acrobatics</h1>

      <h2 id="tumbling">Tumbling</h2>

      <ul>
        <li>
          <p>
            Cartwheel: A sideways tumbling skill in which one foot steps forward
            and the other foot follows, *** ***** are **** placed on the ground
            *** the body is ********* forward and over.
          </p>
        </li>
        <li>
          <p>
            Roundoff: A tumbling skill in which the gymnast approaches the
            ******* point, performs a cartwheel with their hands together, and
            then lands with *** feet together.
          </p>
        </li>
        <li>
          <p>
            Aerial cartwheel: A ******** ***** where the ******* takes off from
            *** foot, spins sideways in the air, and ***** on *** other foot.
          </p>
        </li>
        <li>
          <p>
            Front handspring: A tumbling ***** where the gymnast runs forward,
            jumps onto their hands, and lands on their feet.
          </p>
        </li>
        <li>
          <p>
            Back handspring: A tumbling skill where the gymnast jumps backwards
            **** their hands, kicks ***** legs **** their head, and ***** on
            their feet.
          </p>
        </li>
        <li>
          <p>
            Front walkover: A tumbling skill where the gymnast steps forward
            onto one foot, lifts the other leg and extends it forward, then uses
            ***** arms to flip their body over and land on *** ****** foot.
          </p>
        </li>
        <li>
          <p>
            Back walkover: A tumbling ***** where the gymnast steps backwards
            onto one foot, lifts the other *** and extends it backwards, then
            uses their arms ** **** their body over *** land on *** ****** foot.
          </p>
        </li>
        <li>
          <p>
            Butterfly kick: A tumbling ***** where the gymnast ***** ** the air,
            turns their body sideways, and kicks both legs out to *** side.
          </p>
        </li>
        <li>
          <p>
            **** aerial: A tumbling ***** where the gymnast ***** off from one
            foot, spins sideways in the air, and lands on the same foot.
          </p>
        </li>
        <li>
          <p>
            Front aerial: A tumbling ***** where the gymnast takes off from one
            foot, spins forward in the air, and lands on *** opposite foot.
          </p>
        </li>
        <li>
          <p>
            Layout stepout: A tumbling skill where the gymnast performs a back
            handspring without ***** their ***** to push off *** ground, instead
            they arch their **** and **** in a layout position.
          </p>
        </li>
        <li>
          <p>
            Roundoff back handspring series: A tumbling sequence where the
            gymnast performs a roundoff followed immediately by a back
            handspring.
          </p>
        </li>
        <li>
          <p>
            Standing back tuck: A tumbling skill where the gymnast jumps
            vertically, tucks their knees to their chest, and performs a
            backwards somersault, landing on ***** feet.
          </p>
        </li>
      </ul>
      <h2 id="contortion">Contortion</h2>
      <ul>
        <li>
          <p>
            Chest stand: A ********** skill where the performer lies on their
            back, lifts their legs over their head, and rests their feet on the
            ground behind their head.
          </p>
        </li>
        <li>
          <p>
            Elbow stand: A contortion ***** where the performer balances on
            their elbows, with their hands ******* together *** their legs
            extended upwards.
          </p>
        </li>
        <li>
          <p>
            Forearm stand: A contortion skill where *** performer balances **
            ***** forearms, with their ***** clasped together and their legs
            extended upwards.
          </p>
        </li>
        <li>
          <p>
            Handstand: A ********** ***** where the performer balances ** *****
            hands, with their body straight and their legs vertical.
          </p>
        </li>
        <li>
          <p>
            Scorpion: A contortion skill where the performer ****** on *** leg,
            and bends the other leg backwards *** upwards, ******* onto *** foot
            with one or **** hands.
          </p>
        </li>
        <li>
          <p>
            Needle: A contortion ***** where the performer stands on one leg,
            and lifts the other leg upwards and backwards, with their body bent
            forwards and their **** reaching towards their foot.
          </p>
        </li>
        <li>
          <p>
            **** backbend: A contortion skill where the performer arches their
            **** *** lifts their body up, with their hands on the ground behind
            them.
          </p>
        </li>
      </ul>

      <h1>Ballroom &nbsp;</h1>

      <h2 id="ballroom tango">Tango:</h2>
      <ul>
        <li>
          <p>
            Walks: ******* or ******** steps ***** by both partners in a
            straight line
          </p>
        </li>
        <li>
          <p>
            Ochos: Figure-eight-**** movements done by pivoting on one ****
            while the other foot traces a half circle around it
          </p>
        </li>
        <li>
          <p>
            Corte: A sharp stop where the leader draws their partner into their
            side
          </p>
        </li>
        <li>
          <p>
            Promenade: A ********* **** where the partners move side by side **
            a ****** position
          </p>
        </li>
        <li>
          <p>
            Fan: A figure where the follower opens up like a fan, with ***
            leader creating a circular motion
          </p>
        </li>
        <li>
          <p>
            Twist turn: A ***** turn where both partners twist their ****** in
            opposite directions
          </p>
        </li>
        <li>
          <p>
            Gancho: A hooking ****** where one partner&apos;s leg wraps around
            *** other partner&apos;s leg
          </p>
        </li>
        <li>
          <p>
            Barrida: A sweeping motion where one partner&apos;s foot sweeps the
            other partner&apos;s foot away
          </p>
        </li>
        <li>
          <p>Parada: A stop where *** leader ***** their ******* ** place</p>
        </li>
        <li>
          <p>
            Sandwich: A figure where the follower is ********** between ***
            leader and another object, like a wall
          </p>
        </li>
        <li>
          <p>
            Cross: A cross step ***** the follower crosses their feet while the
            leader steps ******* or back
          </p>
        </li>
        <li>
          <p>
            Back sacada: A motion where *** leader steps ******** while the
            follower&apos;s leg &quot;saccades&quot; or displaces backward
          </p>
        </li>
      </ul>
      <h2 id="ballroom waltz">Waltz:</h2>
      <ul>
        <li>
          <p>
            Natural turn: A **** to *** right ***** both partners turn in a
            natural direction
          </p>
        </li>
        <li>
          <p>
            Reverse turn: A turn to the left where both partners turn ** a
            reverse direction
          </p>
        </li>
        <li>
          <p>
            Chasse from promenade position: A traveling step where the partners
            move **** by side ** an open position
          </p>
        </li>
        <li>
          <p>
            Natural spin turn: A turn to the right where the ****** pivots on
            ***** left foot while the follower steps around them
          </p>
        </li>
        <li>
          <p>
            ****** reverse spin: A turn to the **** where the ****** pivots on
            their right foot while the follower steps around them twice
          </p>
        </li>
        <li>
          <p>
            Back lock: A figure where both partners step backward and ****
            &quot;lock&quot; their feet together
          </p>
        </li>
        <li>
          <p>
            Weave: A figure where *** ******** weave in *** out ** **** other
          </p>
        </li>
        <li>
          <p>
            ****** change: A figure where the follower ***** to the side while
            the leader steps forward
          </p>
        </li>
        <li>
          <p>
            Whisk: A figure where the ****** steps forward while the follower
            steps to the side
          </p>
        </li>
        <li>
          <p>
            Contra check: A figure where **** partners take a step ******* and
            then check their movement
          </p>
        </li>
        <li>
          <p>
            Hover corte: A stop where the leader holds their partner in place
            ***** stepping back
          </p>
        </li>
      </ul>
      <h2 id="ballroom foxtrot">Foxtrot:</h2>
      <ul>
        <li>
          <p>
            Feather step: A traveling figure where both partners move forward
            diagonally
          </p>
        </li>
        <li>
          <p>
            Three step: A ********* figure where both ******** **** ***** steps
            forward
          </p>
        </li>
        <li>
          <p>
            Natural turn: A turn to the right where both partners **** in a
            natural direction
          </p>
        </li>
        <li>
          <p>
            Reverse turn: A turn to the left where both partners turn ** a
            reverse direction
          </p>
        </li>
        <li>
          <p>
            Chasse: A traveling step where the partners move side ** side in a
            closed position
          </p>
        </li>
        <li>
          <p>
            Hover feather: A traveling figure where both ******** move forward
            while the leader pivots on their left foot
          </p>
        </li>
        <li>
          <p>
            Reverse wave: A traveling figure where the partners move backward
            diagonally
          </p>
        </li>
        <li>
          <p>
            Natural weave: A figure where the follower weaves around *** leader
          </p>
        </li>
        <li>
          <p>
            Telemark: A **** to the left where the leader pivots on their left
            foot while the follower steps ****** them
          </p>
        </li>
        <li>
          <p>
            ****** feather: A figure where the follower weaves around the leader
            in a curved path
          </p>
        </li>
        <li>
          <p>
            Back feather: A figure ***** the follower weaves ****** the leader
            ***** moving backward
          </p>
        </li>
      </ul>
      <h2 id="ballroom quickstep">Quickstep:</h2>
      <ul>
        <li>
          <p>
            ******* turn to right: A quick turn to the right, taking *** step
            with *** left foot and then pivoting on the ball ** the right foot.
          </p>
        </li>
        <li>
          <p>
            Natural turn and hesitation: A turning step to the left followed by
            a quick ***** or hesitation on the right foot.
          </p>
        </li>
        <li>
          <p>
            Progressive chasse: A side **** to the left followed by a quick step
            with the right foot and a closing step with the left foot.
          </p>
        </li>
        <li>
          <p>
            Forward lock step: A forward step with the left foot followed by
            crossing *** right foot over the left, stepping forward with ***
            left foot, and then a closing step with *** right foot.
          </p>
        </li>
        <li>
          <p>
            Double reverse spin: A turning step to *** right followed by a quick
            pivot on *** ball of the right foot, a backward step with the left
            foot, and then another quick ***** on *** right foot.
          </p>
        </li>
        <li>
          <p>
            ****** chasse ** right: A **** step ** *** right followed by a quick
            step with *** left foot and a closing step with the ***** foot,
            ******** ***** ***** in a row.
          </p>
        </li>
        <li>
          <p>
            Quick open reverse: A ******** **** with the left foot followed by
            crossing the ***** foot behind the left, ****** a step back **** the
            left foot, and **** a ***** **** back with the right foot while
            opening up to the right.
          </p>
        </li>
        <li>
          <p>
            Fishtail: A forward step with *** left foot followed by crossing the
            right foot behind the left, a side step to the left, and then a
            ***** closing step with the right foot.
          </p>
        </li>
        <li>
          <p>
            Running finish: A forward **** with the **** **** followed ** a
            backward step **** the right foot and then a side step ** *** left,
            with the left foot crossing behind the right foot ****** taking a
            quick closing **** with the right foot.
          </p>
        </li>
        <li>
          <p>
            Zigzag: A series of forward *** backward steps, alternating between
            left and right feet.
          </p>
        </li>
      </ul>
      <h2 id="ballroom rumba">Rumba:</h2>
      <ul>
        <li>
          <p>
            Basic movement: A series ** slow, rhythmic ***** in place, *****
            accompanied by a subtle swaying of the hips.
          </p>
        </li>
        <li>
          <p>
            Cucaracha: A quick, side-to-side step **** the left foot, followed
            by a weight shift onto *** right foot, and then a quick step back to
            the left foot.
          </p>
        </li>
        <li>
          <p>
            Underarm turn to right: A turning step to the right, *****
            accompanied by an underarm turn for the partner.
          </p>
        </li>
        <li>
          <p>
            Cross body lead: A forward step with the left foot ******** ** a
            side step to the right, leading into a turn to the left and a
            backward step with the ***** foot.
          </p>
        </li>
        <li>
          <p>
            Shoulder to shoulder: A series of forward and ******** steps, often
            with partners facing each other and using their shoulders to
            maintain connection.
          </p>
        </li>
        <li>
          <p>
            Cuban rock: A forward and backward rocking motion with the hips.
          </p>
        </li>
        <li>
          <p>
            Spiral: A turning **** to the left or right, with the ******** foot
            crossing over *** ******** foot.
          </p>
        </li>
        <li>
          <p>
            Open hip twist: A turning step to the left, with *** left foot
            crossing over the right foot *** the **** ******** to the left.
          </p>
        </li>
        <li>
          <p>
            Hockey stick: A ******* step **** *** left foot followed by a
            backward step **** the ***** foot and a pivot to the left, leading
            into a side **** to the left and then a ***** closing step with the
            right foot.
          </p>
        </li>
        <li>
          <p>
            Alemana: A forward step with the left foot followed by a ********
            step with the right **** *** then a quick turn to *** right, leading
            **** a **** step with the left foot and a closing **** **** the
            right foot.
          </p>
        </li>
        <li>
          <p>
            Fan: A series of steps **** create a fan shape, often starting ****
            a forward step **** the left foot and then pivoting on the ball of
            the left foot to turn the body to the left.
          </p>
        </li>
      </ul>
      <h2 id="ballroom cha-cha">Cha Cha:</h2>
      <ul>
        <li>
          <p>
            ***** movement: The basic step in *** Cha involves taking a step
            ******* with the left foot, ******** by a step to the **** with the
            ***** foot, *** **** a step together with the left foot. This is
            repeated in the opposite direction, starting with *** right foot.
          </p>
        </li>
        <li>
          <p>
            Cuban breaks: A ****** of hip and leg movements that involve bending
            and ************* the knees, and moving *** hips back and forth in
            time **** *** music.
          </p>
        </li>
        <li>
          <p>
            Spot turns: A turning ******** where the dancer pivots on one foot
            while the ***** foot steps forward or backward in place.
          </p>
        </li>
        <li>
          <p>
            Underarm turn to right: A turn where *** follower turns under the
            leader&apos;s arm to the right.
          </p>
        </li>
        <li>
          <p>
            Three cha cha chas: Three quick steps taken in place, typically ****
            to create a syncopated rhythm in the music.
          </p>
        </li>
        <li>
          <p>
            Fan: A pattern where the leader steps to the **** with the left
            foot, then steps forward with *** right foot while turning 180
            degrees to face the ******** direction, and then steps back **** the
            left foot.
          </p>
        </li>
        <li>
          <p>
            Hockey stick: A ******* ***** the ****** steps forward with the left
            foot, then steps to the side with the right foot while turning 90
            degrees to the left, and then steps back with the left foot while
            turning 180 ******* to the right.
          </p>
        </li>
        <li>
          <p>
            Natural top: A turn where the leader and follower ****** in a
            clockwise direction, **** the ****** stepping forward on *** left
            foot and the ******** stepping back on the right foot.
          </p>
        </li>
        <li>
          <p>
            Alemana: A pattern where the leader steps forward with *** left
            foot, then steps to the side with the right foot while turning 90
            ******* ** the left, and then steps forward with the left foot while
            turning 180 degrees ** the right.
          </p>
        </li>
        <li>
          <p>
            New York: A pattern where the leader ***** forward with the left
            foot, then steps to *** **** with *** ***** foot, then steps ****
            with the left foot, and then steps in place **** the right foot.
          </p>
        </li>
        <li>
          <p>
            Time step: A step where the leader steps forward on the **** foot,
            then ***** back on the right foot, then ***** in place with the left
            foot, *** then steps ** ***** **** the right foot.
          </p>
        </li>
      </ul>
      <h2 id="ballroom samba">Samba:</h2>
      <ul>
        <li>
          <p>
            Whisks: A step where the ****** steps forward with the left foot and
            brushes the right foot forward, **** steps forward **** the right
            foot *** brushes the left foot forward.
          </p>
        </li>
        <li>
          <p>
            Samba walks: A series of ******* steps taken in place, *********
            with a bounce or sway in the hips.
          </p>
        </li>
        <li>
          <p>
            Volta: A turn where the leader and follower rotate in a
            counter-********* direction, **** the leader ******** back on ***
            left foot *** the follower ******** forward on the right foot.
          </p>
        </li>
        <li>
          <p>
            ******** walks: A ****** of walking ***** taken in place, where the
            ****** and follower cross their feet over each other.
          </p>
        </li>
        <li>
          <p>
            **** fogos: A step where the ****** steps ******* with the left foot
            and brushes the right foot back, then steps **** with the right foot
            and ******* the left foot forward.
          </p>
        </li>
        <li>
          <p>
            ******** with lateral ********* voltas: A pattern ***** the leader
            steps to the side with the **** foot, then steps back with the right
            foot while turning ** face the left, and then steps to the **** with
            the left foot while turning 90 degrees to the right.
          </p>
        </li>
        <li>
          <p>
            Corta jaca: A turn where the leader and follower rotate in a
            clockwise direction, with the leader stepping forward on the ****
            foot and the follower stepping back on the ***** foot.
          </p>
        </li>
        <li>
          <p>
            Reverse turn with whisk: A turn where the ****** steps **** ** the
            left foot and turns to face the right, then steps back on the right
            foot and turns to face the left, *** then steps forward on *** left
            foot while ******** the right foot forward.
          </p>
        </li>
        <li>
          <p>
            Samba rolls: A series of turns where the ****** and ******** ******
            around each other
          </p>
        </li>
        <li>
          <p>
            Promenade runs: A sequence of steps where the partners move along
            the line of dance while maintaining a promenade position. The *****
            ********* involve a series of running steps and pivots.
          </p>
        </li>
        <li>
          <p>
            Shadow rolls: A move ** which *** partners are facing **** other in
            a shadow position, **** the lead&apos;s right hand holding the
            follow&apos;s left hand. The lead then leads the follow to roll
            backwards over their left shoulder *** then forwards over their
            right shoulder while continuing to hold hands.
          </p>
        </li>
      </ul>

      <h2 id="ballroom jive">Jive:</h2>

      <ul>
        <li>
          <p>
            ***** in place: A basic step where the partners bounce ** place with
            the knees slightly bent, often accompanied by swiveling the hips and
            moving the arms.
          </p>
        </li>
        <li>
          <p>
            Fallaway rock: A step where *** lead steps back on their left foot
            while leading the ****** to rock forward onto their right foot,
            ******** by the lead stepping forward on their right foot while
            leading the follow to rock back onto their left foot.
          </p>
        </li>
        <li>
          <p>
            Change ** places right to left: A move where the partners ******
            places by moving to *** **** and crossing their feet, with the ****
            leading *** ****** to move to their left.
          </p>
        </li>
        <li>
          <p>
            Change of places left to right: A move where *** ******** switch
            places by moving ** the **** and crossing ***** feet, with the lead
            leading the follow ** move to their right.
          </p>
        </li>
        <li>
          <p>
            Hip bump: A move where the partners bump hips while bouncing in
            place or stepping.
          </p>
        </li>
        <li>
          <p>
            American spin: A move where the lead ***** the follow to spin around
            in a **** turn by ******* their left arm and turning their *** body
            to *** left.
          </p>
        </li>
        <li>
          <p>
            Underarm turn to right: A move ***** the lead ***** the follow to
            turn under ***** right arm, typically while moving to *** right.
          </p>
        </li>
        <li>
          <p>
            Walks: A basic move ***** the partners take ***** forward, backward,
            or ** the side.
          </p>
        </li>
        <li>
          <p>
            Stop *** go: A move where the partners alternate between stopping
            and starting while moving forward.
          </p>
        </li>
        <li>
          <p>
            Chicken walks: A move where the partners take short, quick ***** in
            place, often accompanied by ******** the arms.
          </p>
        </li>
        <li>
          <p>
            Flicks: A move where the partners flick ***** feet out to *** side
            or back, ********* with a sharp motion.
          </p>
        </li>
      </ul>

      <h2 id="ballroom conga">Conga:</h2>
      <ul>
        <li>
          <p>
            Basic step: A simple step where *** ******** step forward *** then
            bring their feet together, followed by stepping backward and
            bringing their feet together.
          </p>
        </li>
        <li>
          <p>
            Grapevine: A **** ***** the partners step ** the side and cross
            their feet ****** each other, followed by stepping to the side and
            crossing their **** in front ** each other.
          </p>
        </li>
        <li>
          <p>
            Fan: A move where the lead leads the follow ** step ** the side and
            then pivot on one **** while the other foot kicks out to the side.
          </p>
        </li>
        <li>
          <p>
            Check turn: A move where the lead leads *** follow to turn 180
            ******* while stepping to the **** and then stopping abruptly.
          </p>
        </li>
        <li>
          <p>
            Cumbia: A **** where the partners step ** *** side and then bring
            their feet together, followed by stepping to the side and kicking
            one foot out to the side.
          </p>
        </li>
        <li>
          <p>
            Pop turn: A move where the lead leads *** follow ** turn 180 degrees
            by lifting their right *** and then ******* the follow to ***** **
            their left foot.
          </p>
        </li>
        <li>
          <p>
            Spot turn: A move where *** partners turn in place *******
            traveling.
          </p>
        </li>
        <li>
          <p>
            ******** turn: A **** where the lead ***** the follow to turn under
            their arm, typically while moving forward.
          </p>
        </li>
        <li>
          <p>
            ******** to shoulder: A move where the partners step ******* and
            then bring their ******* shoulders ******** while facing the same
            direction.
          </p>
        </li>
        <li>
          <p>
            **** around: A move where the **** leads the follow ** step to the
            side and then ***** ** one foot ***** the other foot wraps around
            the lead&apos;s leg.
          </p>
        </li>
      </ul>
      <h1>Barathynatham( Indian Classical Dance)</h1>
      <h2 id="bharatynatham adavus">Adavus (basic steps):</h2>

      <p>
        Adavus are *** basic ***** in Barathynatham that form the foundation of
        the ***** form. There *** ******* types of adavus, and each one has a
        unique rhythm and movement pattern.
      </p>

      <ul>
        <li>
          <p>
            Tatta Adavu:a type of adavu that involves tapping the feet on the
            ground in a rhythmic pattern.
          </p>
        </li>
        <li>
          <p>
            Natta Adavu: a type of adavu **** involves jumping and landing on
            one foot while the other foot is lifted.
          </p>
        </li>
        <li>
          <p>
            Visharu Adavu: a **** of adavu **** involves sideways movements ****
            the feet while the upper body remains still.
          </p>
        </li>
        <li>
          <p>
            Teermanam Adavu: a type of adavu that involves a continuous movement
            pattern with *** **** while the upper **** remains still.
          </p>
        </li>
        <li>
          <p>
            Kudittametta Adavu:a type of adavu that involves jumping and landing
            on both feet together in a rhythmic pattern.
          </p>
        </li>
        <li>
          <p>
            Purna Mandala Adavu: a **** of ***** that involves circular
            ********* with the feet ***** the upper body remains still.
          </p>
        </li>
        <li>
          <p>
            Mandi Adavu: a type of adavu that involves a rhythmic ********
            ******* with the feet while *** upper body sways.
          </p>
        </li>
        <li>
          <p>
            Sarikal Adavu: a type of adavu that involves diagonal ********* with
            the feet ***** the upper **** remains still.
          </p>
        </li>
      </ul>

      <h2 id="bharatynatham hastas">Hastas (**** gestures):</h2>

      <p>
        Hastas are hand gestures used in Barathynatham to convey emotions and
        expressions. There are various types of hastas, each one representing a
        different ******* ** concept.
      </p>

      <ul>
        <li>
          <p>
            Asamyukta Hasta: a single-**** gesture that is used to *********
            various ******** and objects.
          </p>
        </li>
        <li>
          <p>
            ******** Hasta: a two-hand gesture that is used to represent various
            emotions *** objects.
          </p>
        </li>
        <li>
          <p>
            ****** Hasta: a hand gesture used ** Barathynatham to convey
            abstract concepts and rhythms.
          </p>
        </li>
        <li>
          <p>
            Nritya Hasta: a hand gesture used in Barathynatham to convey
            emotions and expressions.
          </p>
        </li>
        <li>
          <p>
            Aartha Hasta: a hand ******* used in Barathynatham to convey objects
            and things.
          </p>
        </li>
        <li>
          <p>
            Pataka Hasta: a hand gesture used in Barathynatham to convey
            strength and power.
          </p>
        </li>
        <li>
          <p>
            ********* Hasta:a hand gesture used ** Barathynatham to convey
            fighting or battle.
          </p>
        </li>
        <li>
          <p>
            Ardhachandra Hasta:a **** gesture used ** Barathynatham to convey
            the ***** of a crescent moon.
          </p>
        </li>
        <li>
          <p>
            ********** Hasta: a hand gesture used in Barathynatham to convey a
            bird&apos;s beak.
          </p>
        </li>
        <li>
          <p>
            Shikhara Hasta: a hand gesture used in Barathynatham to convey a
            temple or a mountain.
          </p>
        </li>
        <li>
          <p>
            Kapittha Hasta: a hand gesture used in ************* to convey a
            flower bud.
          </p>
        </li>

        <li>
          <p>
            Katakamukha Hasta: This is a hand ******* ** Indian classical dance
            that resembles the face of a lion. ** is ****** by keeping the thumb
            inwards, the index and little finger pointing outwards, *** the
            middle *** ring fingers **** and ******** *** palm.
          </p>
        </li>
        <li>
          <p>
            Suchi Hasta: This is a hand gesture that represents a ****** **
            Indian classical dance. It is formed by holding the ***** finger
            straight and the ***** fingers curled towards the palm.
          </p>
        </li>
        <li>
          <p>
            Chandrakala Hasta: **** is a hand gesture **** ********** *** shape
            of a ******** moon in Indian classical dance. It is formed by
            holding the index and little finger ******** and slightly apart,
            while the other fingers are curled towards the palm.
          </p>
        </li>
      </ul>

      <h2 id="bharatynatham abhinaya">Abhinaya (facial expressions): </h2>

      <p>
        **** refers to the use ** facial expressions to convey emotions *** ****
        a story in Indian classical dance. There are many different expressions
        **** can be used, **** with its own specific ******* and interpretation.
      </p>

      <ul>
        <li>
          <p>
            Shanta: This is a ****** expression of peace or tranquility in
            Indian ********* dance, often portrayed with a calm and serene
            expression.
          </p>
        </li>
        <li>
          <p>
            Hasita: **** is a facial ********** of ******** or *** in Indian
            ********* dance, often ********* with a smile and ********* eyes.
          </p>
        </li>
        <li>
          <p>
            Chatura: This is a facial expression of *** or cleverness in Indian
            classical dance, often portrayed **** a raised eyebrow and a
            quizzical expression.
          </p>
        </li>
        <li>
          <p>
            Adbhuta: **** is a facial expression of ****** or amazement in
            Indian classical dance, often portrayed with wide eyes and an open
            mouth.
          </p>
        </li>
        <li>
          <p>
            Bhayanaka: This is a facial expression of fear or terror in Indian
            classical dance, often portrayed with **** eyes and a tense
            expression.
          </p>
        </li>
        <li>
          <p>
            Karuna: This is a facial expression of compassion or sadness in
            Indian classical dance, often portrayed with a gentle and
            sympathetic expression.
          </p>
        </li>
        <li>
          <p>
            Raudra: This is a facial ********** of anger or rage ** Indian
            classical dance, often ********* with a furrowed brow and a fierce
            expression.
          </p>
        </li>
        <li>
          <p>
            Veera: This ** a facial expression ** heroism or bravery in Indian
            classical dance, often portrayed with a proud and determined
            expression.
          </p>
        </li>
        <li>
          <p>
            Bibhatsa: This is a facial expression ** ******* ** revulsion in
            Indian classical dance, ***** portrayed with a wrinkled nose and a
            disgusted expression.
          </p>
        </li>
      </ul>

      <h2 id="barathynatham raagas">Ragas</h2>
      <ul>
        <li>
          <p>
            Bhairavi: Bhairavi ** a **** in Indian classical music that is
            associated with devotion, love, and compassion. It is usually played
            in the early morning hours and is characterized by a deep,
            ********** quality.
          </p>
        </li>
        <li>
          <p>
            Kalyani: ******* is a **** in Indian classical music that is
            ********** with happiness, joy, and celebration. ** is usually
            played in the late morning ***** and is characterized by a bright
            and cheerful quality.
          </p>
        </li>
        <li>
          <p>
            Kambhoji: Kambhoji ** a raga in Indian classical music that is
            associated with love and devotion. It ** usually ****** in the
            evening hours and is characterized by a slow and *************
            quality.
          </p>
        </li>
        <li>
          <p>
            Hamsadhwani: Hamsadhwani is a raga in ****** ********* music that is
            associated with peace and tranquility. It is ******* played in the
            **** evening hours and is characterized ** a serene and meditative
            quality.
          </p>
        </li>
        <li>
          <p>
            Mohana: Mohana is a raga in Indian classical music **** is
            associated **** love *** longing. ** is usually ****** in the
            evening hours *** is characterized by a sweet and melodious quality.
          </p>
        </li>
        <li>
          <p>
            Anandabhairavi: Anandabhairavi is a raga in Indian classical music
            **** is associated with happiness and contentment. It is *******
            played in *** morning hours and is characterized by a joyful and
            ********* quality.
          </p>
        </li>
        <li>
          <p>
            Shanmukhapriya: Shanmukhapriya is a raga in Indian classical music
            that ** associated with ***** and tranquility. It is usually played
            in *** evening hours and is characterized by a soothing and calming
            quality.
          </p>
        </li>
        <li>
          <p>
            Kedaragowla: Kedaragowla is a **** in Indian classical music that is
            associated with devotion and spirituality. It ** usually played in
            the early morning hours and is characterized ** a solemn and
            meditative quality.
          </p>
        </li>
        <li>
          <p>
            Kapi: Kapi is a raga in Indian classical music that is **********
            with love and longing. It is usually played in the ******* ***** ***
            is characterized by a romantic and contemplative quality.
          </p>
        </li>
      </ul>

      <h2> Traditional Dances</h2>

      <ul>
        <li>
          <p>
            Alarippu - Alarippu is an introductory dance item in Bharatanatyam,
            a classical dance form **** South India. ** involves the ******
            performing rhythmic footwork and simple hand gestures in a
            structured pattern. *** purpose of the ******** is ** warm up the
            body and introduce the basic elements ** Bharatanatyam.
          </p>
        </li>
        <li>
          <p>
            Jatiswaram - Jatiswaram is a **** complex dance item in
            Bharatanatyam, which combines rhythmic footwork, hand gestures, and
            intricate choreography. *** ****** ********* performs a series of
            jatis, or ******** patterns, to a set of musical ***** called
            swarams. The purpose ** Jatiswaram is to showcase the dancer&apos;s
            mastery of rhythm and technique.
          </p>
        </li>
        <li>
          <p>
            Shabdam - Shabdam is a dance item ** Bharatanatyam that combines
            expressive hand gestures with graceful movements of the body. It is
            typically performed to a devotional song, and the dancer uses facial
            expressions and subtle movements ** convey the meaning of the
            lyrics. The purpose of Shabdam is ** evoke a sense of devotion and
            spirituality in the audience.
          </p>
        </li>
        <li>
          <p>
            Varnam - Varnam is the centerpiece of a Bharatanatyam performance,
            and is the most complex *** challenging ***** item. It combines
            ********* footwork, expressive hand gestures, and storytelling
            through dance. The ****** portrays a character from Hindu mythology
            or folklore, and uses the dance to convey a ***** ** message. The
            ******* ** Varnam is to showcase *** dancer&apos;s ***** and
            artistry, as well as to entertain and educate the audience.
          </p>
        </li>
        <li>
          <p>
            Padam - Padam is a slow and ********** ***** item in Bharatanatyam,
            which focuses on conveying emotions through movement and expression.
            It is ********* performed to a romantic or devotional song, and the
            dancer uses subtle movements of the body and **** to convey the
            ******* and mood of the lyrics. The ******* of Padam is to evoke a
            sense ** emotion and empathy in the audience.
          </p>
        </li>
        <li>
          <p>
            Javali - Javali is a light-hearted and flirtatious dance item in
            Bharatanatyam, which focuses on ********* the playfulness and charm
            of the lyrics. It ** typically performed ** a ******** or humorous
            song, and the dancer uses graceful movements of the **** and
            expressive hand gestures ** ****** the meaning of *** lyrics. The
            purpose ** Javali ** to entertain and delight the audience.
          </p>
        </li>
        <li>
          <p>
            Tillana - Tillana is a lively and energetic dance item in
            Bharatanatyam, ***** ********* the dancer&apos;s skill and
            athleticism. It is typically ********* to a fast-paced instrumental
            piece, and the dancer uses intricate footwork, quick movements, and
            jumps to match the rhythm of the music. The purpose of Tillana is to
            showcase the dancer&apos;s mastery of rhythm and technique, as ****
            ** to ********* and ******** *** audience.
          </p>
        </li>
        <li>
          <p>
            Shlokam - Shlokam is a devotional dance item in Bharatanatyam, *****
            is ********* to a hymn or prayer from ***** scriptures. The dancer
            uses expressive hand gestures *** movements to convey the meaning
            *** mood of the lyrics, and the purpose of ******* ** to evoke a
            sense of devotion and spirituality in the audience.
          </p>
        </li>
      </ul>
    </div>
  );
}

export default DepthSkills;
