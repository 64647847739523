export default [
  {key: 'Fast + Slow Dynamics',value: "Refers to the speed or tempo at which movements are executed. Fast dynamics involve quick, rapid movements, while slow dynamics involve slower, more controlled movements. The use of fast and slow dynamics in dance adds variation, contrast, and expressiveness to the overall movement quality." },
  {key: 'Sudden + Sustained Dynamics',value: " Describes the abruptness or continuity of movement. Sudden dynamics involve quick and abrupt changes in movement, while sustained dynamics involve smooth and continuous movements. Sudden dynamics create moments of surprise or contrast, while sustained dynamics create a sense of flow and continuity." },
  {key: 'Acceleration + Deceleration',value: "the change in speed or intensity of movement. Acceleration refers to an increase in speed or intensity, while deceleration refers to a decrease. The use of acceleration and deceleration adds dynamic variation and rhythmic interest to dance sequences." },
  {key: 'Strong + Light Movements',value: "the amount of energy or force applied to movements. Strong movements involve power, force, and intensity, while light movements involve gentleness, delicacy, and grace. The contrast between strong and light movements creates dynamic range and emotional expression in dance." },
  {key: 'Direct + Indirect Movements',value: "Refers to the clarity or fluidity of movement pathways. Direct movements involve clear and straight pathways, while indirect movements involve curved or circuitous pathways. Direct movements create strong lines and clear intentions, while indirect movements create a sense of fluidity, grace, and unpredictability." },
  {key: 'Choreographic Intent',value: "Choreographic intent guides the choreographer in creating specific movements, motifs, and sequences that help express their intended meaning or evoke a particular response from the audience." },
  {key: 'Choreographic Mood',value: "Refers to the speed or tempo at which movements are executed. Fast dynamics involve quick, rapid movements, while slow dynamics involve slower, more controlled movements. The use of fast and slow dynamics in dance adds variation, contrast, and expressiveness to the overall movement quality." },
  {key: 'Style Fusions',value: "It involves combining elements, movements, or characteristics from two or more distinct dance styles to create a unique and eclectic movement vocabulary. Style fusions allow choreographers to explore new possibilities, challenge traditional boundaries" },
  {key: 'Movement Flow',value: "Movement flow refers to the smoothness, continuity, and connectedness of movements within a dance. It focuses on the seamless transition and organic progression between different movement phrases, sequences, or motifs." },
];
